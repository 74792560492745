import React from "react";
import styled from "styled-components";
import Pic from "../../Assets/CompanyPage/Hero.png";

function CompanyHero() {
  const Contain = styled.div`
    ${'' /* background-image: linear-gradient(to right, #54006b, #cc123d); */}
    /* padding: 300px;  */

    @media only screen and (max-width: 921px) {
      /* padding: 38px;
      padding-top: 122px; */
    }
  `;
  const Text = styled.h4`
    color: #ca3040;
    font-weight: 400;
    display: flex;
    align-items: center;
  `;

  const Text1 = styled.h4`
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
  `;

  const Card = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 20px;
    margin: 0px 50px 50px 50px;
    border-radius: 10px;
    box-shadow: 10px 10px 40px 0px #2f2f2f;
  `;

  const Image = styled.img`
    height: 500px;
    padding-top: 90px;

    @media only screen and (max-width: 921px) {
      /* padding: 38px;
      padding-top: 122px; */
      height: 370px;
    }
  `;
  const ImgContain = styled.div``;
  return (
    <>
      <Contain>
        <div className="row m-0 p-5">
          <div className="col-md-6 d-flex">
            <Text>
            - Are your Interns and Freshers ready for the job of tomorrow <br />
- Change the traditional way of hiring <br />
- Work with candidates before you hire them <br />
- Find the right talent at minimal cost (1 month free)<br />
                                            
            </Text>
          </div>
          <ImgContain className="col-md-6 d-flex justify-content-center">
            <Image src={Pic} alt="Hero_img" />
          </ImgContain>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Card>
              <Text1>
              Deploy the resources on the Project
              </Text1>
            </Card>
          </div>
          <div className="col-md-4">
          <Card>
              <Text1>
              Candidates Trained and mentored by Industry Experts of Inmovidu
              </Text1>
            </Card>
          </div>
          <div className="col-md-4">
          <Card>
              <Text1>
              Hire from the trained set of Students 
              </Text1>
            </Card>
        </div>
      </div>
      </Contain>
    </>
  );
}

export default CompanyHero;
