import React from "react";
import styled from "styled-components";
import WorksCard from "../Extra/WorksCard";
import Pic1 from "../../../Assets/Landing_Page/Icons/Hiring.png";
import Pic2 from "../../../Assets/Landing_Page/Icons/Discuss.png";
import Pic3 from "../../../Assets/Landing_Page/Icons/Updates.png";
import Pic4 from "../../../Assets/Landing_Page/Icons/Support.png";


function Works() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 15px;
  `;
  const Head = styled.p`
    color: #fff;
    margin: 20px !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Sub = styled.p`
    color: #fff;
  `;
  return (
    <Contain>
      <div className="text-center">
        <Head>How it works </Head>
        <Sub>How does it work for your Company? </Sub>
      </div>
      <div className="row m-0 mb-4">
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic1}
            head="1. Hiring Plan"
            lone="Share hiring plan"
            ltwo="Share skill requirements"
            lthree="Share timelines for hiring "
          />  
        </div>
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic2}
            head="2. Discuss Framework"
            lone="Company approve the framework and timelines"
            ltwo="Deploy the resources on the Project first "
            lthree="Inmovidu launches the requirements across 4000+ colleges"
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic3}
            head="3. Updates & Progress"
            lone="Candidates mentored by Industry Experts"
            ltwo="Weekly progress report for the interns shared"
            lthree="Projects assessed by Mentors and Company"
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic4}
            head="4. Hiring Support"
            lone="Interns available to work on project for no cost for up to 2.5 months*"
            ltwo="Hire from the trained pool of candidates "
            lthree="Conduct Interview and offer candidates at no cost"

          />
        </div>
      </div>
    </Contain>
  );
}

export default Works;
