import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import pic from "../../../Assets/hero.png"
import CarouselMain from '../Extra/CarouselMain'

function Hero() {
  const Contain = styled.div`
    /* background: white; */
    
  `
  const Image = styled.img`
      height: 700px;
      margin-top: 15px;
      padding-top: 140px;
     

      @media only screen and (max-width: 768px ){
        height: 487px;
        padding-top: 100px
    }
    @media only screen and (max-width: 768px ){
        height: 487px;
        padding-top: 4px
    }
  ` 
  const InnerCol = styled.div`
      padding: 100px 50px;

      @media only screen and (max-width: 768px) {
      padding-top: 100px;
      padding-right: 0px;
      padding-left: 0px;
      /* margin-bottom: 30px */
    }
    @media only screen and (max-width: 820px) {
      padding: 60px 0px;
    }
  `
  return (
    <Contain className="row m-0 gx-1 mb-5 sec__one">
      <div className="col-md-7 gx-3">
        <div className="row m-0">
          <InnerCol className="col-md-12">
          <CarouselMain />
          </InnerCol>
          {/* <span><Button>Find More </Button></span> */}
          
        </div>
      </div>
      <div className="col-md-5 d-flex justify-content-end">
        <Image src={pic} alt="hero_img" className='img-fluid'/>
      </div>
    </Contain>
  )
}

export default Hero