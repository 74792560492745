import React from 'react'
import  styled  from 'styled-components';
import Pic1 from "../../../Assets/project.png"

function TabContent(prop) {
    const Text = styled.div`
    color: #000;
    text-align: left;
  `
  const LeftCol = styled.div`
    /* padding: 0px 80px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
    padding: 10px 0px 10px 50px;

    @media only screen and (max-width: 768px) {
      padding: 10px;
    }
    @media only screen and (max-width: 820px) {
      padding: 10px;
    }
  `
    const List = styled.li`
    list-style-type: disc;
    font-size: 16px;
    /* line-height: 100%; */
    margin-bottom: 10px
  `;
    const Para = styled.p`
    display: flex;
    justify-content: center;
    align-item: center;
    /* padding: 150px; */
    padding: 60px 50px;
    @media only screen and (max-width: 921px) {
      padding: 15px;
    }
  `;

    const Title = styled.p`
    font-size: 19px;
    font-weight: 600;

  `;
    const Image = styled.img`
    height: 560px;
    padding: 10px 50px 10px 0px;
`
  return (
    <div>
    <div className='row'>
        <div className='col-md-6'>
        <LeftCol>
          <Text>
            <p>
            <Title>Are you ready to transform the way you hire and get the best people?</Title>
            <Para>
                <ol>
                  <List>
                   {prop.list1}
                  </List>
                  <List>
                   {prop.list2}
                  </List>
                  <List>
                   {prop.list3}
                  </List>
                  <List>
                   {prop.list4}
                  </List>
                  
                </ol>
              </Para>
            </p>
          </Text>
        </LeftCol>
        </div>
        <div className='col-md-6'>
        <Image src={Pic1} alt="Hero_img"  />
        </div>
    </div>
    </div>
  )
}

export default TabContent