import React from "react";
import styled from "styled-components";
import pic from "../../Assets/About_Page/Hero.png";

function AboutHero() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 60px;
  `
  const Image = styled.img`
        margin-top: 100px;
        height: 470px;

        @media only screen and (max-width: 768px){
          height: 350px;
          margin-top: 20px;
        }
  `

  const Text = styled.p`
      color: #fff;
      margin-top: 200px;
      font-size: 20px;

      @media only screen and (max-width: 768px){
        margin-top: 40px;
      }
  `
  return (
    <Contain>
      <div className="row m-0">
        <div className="col-md-6 ">
          <Text>
            Upgrade your Freshers and Interns Workforce with us
            <br />
            <br />
            Acquiring the right talent is the most important key to growth. And
            we are the trusted source for the right candidates. 
            <br />
            Save time and
            money by recruiting with us.
          </Text>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <Image src={pic} alt="hero" className="img-fluid" />
        </div>
      </div>
    </Contain>
  );
}

export default AboutHero;
