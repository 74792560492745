import React from "react";
import CompanyHero from "./CompanyHero";
import CompanyMore from './CompanyMore';
import CompanyTab from "../Common/Tab";
import TabOne from "./TabCard/TabOne";
import TabTwo from "./TabCard/TabTwo";
import TabThree from "./TabCard/TabThree";

function CompanyMain() {
  return (
    <div>
      <CompanyHero />
      <CompanyTab 
        tabval1={<TabOne />}
        tabval2={<TabTwo />}
        tabval3={<TabThree/>}
        name1="For Startup"
        name2="For MSME"
        name3="For Large Company"
      />
      {/* <CompanyProfile /> */}
      <CompanyMore />
    </div>
  );
}

export default CompanyMain;