import React from 'react'
import Pic from "../../../Assets/CompanyPage/Middle.png"
import  styled  from 'styled-components';
import TabContent from './TabContent';

function TabTwo() {
       
  const Head = styled.h3`
  margin-bottom: 15px;
  
`
  return (
    <div className='m-0 p-0'>
        <img src={Pic} alt="head" />
        <div className='d-flex text-center justify-content-center mb-3'>
    <Head>For Medium Sized Companies</Head>
    
    </div>
    <div className='text-center mb-3'>
    <TabContent
    list1="Build your Talent Pool : Convert your hiring Requirements into projects and create efficiencies for your HR Team"
    list2="Trained Resources : Our team of experts trains the resources for your company"
    list3="Work with candidates for free for 1 Month before you hire them"
    ></TabContent>
    <p className='text-start m-5 mt-0'><b>Choose the plan that works best for you</b> <br></br> <br></br>
Bring down your hiring costs as we take care of Hiring, Training and deployment of resources
</p>
    </div>
    </div>
  )
}

export default TabTwo