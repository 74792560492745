import React from 'react'
import Pic from "../../../Assets/CompanyPage/Middle.png"
import  styled  from 'styled-components';
import TabContent from './TabContent';

function TabOne() {
  const Head = styled.h3`
    margin-bottom: 15px;
    
  `
  return (
    <div>
        <img src={Pic} alt="head" />
        <div className='d-flex text-center justify-content-center mb-3'>
    <Head>For Startup</Head>
    
    </div>
    <div className='text-center mb-3'>
    <TabContent
    list1="Build your MVP : Share your project. We shall have students build it for you"
    list2="Testing your Prototype: Launch it with our student Community and get feedback"
    list3="Marketing : Extensive Branding with students across India"
    ></TabContent>
    </div>
    </div>
  )
}

export default TabOne