import React from "react";
import styled from "styled-components";
import pic1 from "../../Assets/About_Page/middle_one.png";

function Vision() {
  const Image = styled.img`
    height: 500px;

    @media only screen and (max-width: 768px){
        height: 360px;
    }
  `;
  const ColOne = styled.div``;
  const Contain = styled.div`
    padding: 60px;

    @media only screen and (max-width: 768px){
      padding: 35px;
    }
  `;
  const InnerCard = styled.div`
    background-color: #fae8ec;
    color: #000;
    padding: 20px;
    border-radius: 25px;
  `;
  const Text = styled.p`
    margin-top: 140px;

    @media only screen and (max-width: 768px){
        margin-top: 25px;
    }
  `;
  const Head = styled.div`
    
  `
  return (
    <Contain>
      <div className="row m-0">
        <ColOne className="col-md-6 mb-3 d-flex justify-content-end">
          <Image src={pic1} alt="our_vision" />
        </ColOne>
        <div className="col-md-6 mb-3 d-flex justify-content-center">
          <InnerCard className="">
          <Head className="d-flex text-center justify-content-center">
                <h4> Our Vision</h4>
          </Head>
            <Text>
            <li> Upskill workforce so that the organization can continue ahead</li>
            <li>Deliver superior candidates for the organizations</li>
            <li>customized programs for end to end benefits of organizations</li>
</Text>
          </InnerCard>
        </div>
      </div>
    </Contain>
  );
}

export default Vision;
