import React from "react";
import Choose from "./Choose";
import Hero from "./Hero";
import Number from "./Number";
import PartnerCom from "./PartnerCom";
import ProjectsMain from "./ProjectsMain";
import Success from "./Success";
import WhyUs from "./WhyUs";
import Works from "./Works";
import { FooterContainer } from './footer/footer';

function HeroMain() {
  return (
    <div>
      <Hero />
      <WhyUs />
      <Works />
      <Choose />
      <ProjectsMain /> 
      <PartnerCom />
      <Success />
      <Number /> 
      {/* <FooterContainer />  */}
    </div>
  );
}

export default HeroMain;
