import React from 'react'
import Comsoon from '../Common/Comsoon';
import CompanyTab from '../Common/Tab';
import LearningHero from './LearningHero';
import LearningTabOne from './LearningTabOne';

function LearningMain() {
  return (
    <div>
    <Comsoon/>
        {/* <LearningHero />
        <CompanyTab 
        tabval1={<LearningTabOne />}
        tabval2={<LearningTabOne />}
        tabval3={<LearningTabOne />}
        name1="College"
        name2="Academy"
        name3="Mentors"
        /> */}
    </div>
  )
}

export default LearningMain