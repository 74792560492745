import React from 'react'
import Pic from "../../../Assets/CompanyPage/Middle.png"
import  styled  from 'styled-components';
import TabContent from './TabContent';

function TabThree() {
    const Head = styled.h3`
    margin-bottom: 15px;
    
  `
    return (
      <div className='m-0 p-0'>
          <img src={Pic} alt="head" />
          <div className='d-flex text-center justify-content-center mb-3'>
      <Head>For Large Enterprises</Head>
      
      </div>
      <div className='text-center mb-3'>
      <TabContent
      list1="Hire candidates who are ready to deploy"
      list2="Training support provided by our mentors"
      list3="Review their assignment, offer feedback, meet your team virtually and guide them to ensure that they are all on the same page."
      list4="Hire from our pool of students who have been specially trained for your projects"
      ></TabContent>
      </div>
      </div>
  )
}

export default TabThree