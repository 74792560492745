// import React from "react";
import "./CarouselStyle.css";
// import styled from "styled-components";

// function CarouselMain() {
//   const Contain = styled.div`
//     /* display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-item: center; */
//   `;
//   const Head = styled.p`
//     font-size: 32px;
//     color: #000;
//     @media only screen and (max-width: 768px) {
//     font-size: 16px;
//     }
//     @media only screen and (max-width: 820px) {
//     font-size: 16px;
//     }
//   `;
//   const Carou = styled.div`
//     padding: 40px;
//     color: #707070;
//     font-size: 20px;

//     @media only screen and (max-width: 768px) {
//       font-size: 12px;
//     }
//     @media only screen and (max-width: 820px) {
//       font-size: 12px;
//     }
//   `;
//   return (
//     <Contain>
//       <div class="carousel"
//   data-flickity='{ "freeScroll": true }'>
//         <div className="carousel-cell">
//           <Carou>
//             <Head>
//               Get trained candidates ready to take on the jobs of tomorrow
//             </Head>
//             <p style={{ color: "#000" }}>
//               Inmovidutech is a one stop platform that helps you hire skilled
//               Interns and Freshers with Live Project Model
//             </p>
//           </Carou>
//         </div>
//         <div className="carousel-cell">
//           <Carou>
//             <Head>
//               Launch Internships and Placement Opportunities on our platform to
//               students across 4000+ colleges
//             </Head>
//             <p style={{ color: "#000" }}>
//               College education, while good, is inadequate— we are building the
//               talent pool by connecting interested students, learning providers
//               and you via our platform and unique product to help build India’s
//               workforce.
//             </p>
//           </Carou>
//         </div>
//         <div className="carousel-cell">
//           <Carou>
//             <Head>
//               Work on Live Projects in Technical and Non technical domain
//             </Head>
//             <p style={{ color: "#000" }}>
//               Work on Live Projects. Get Paid Internships & Projects for
//               engineering students, Work on Live projects with various companies
//               for IT, CSE, ECE, EEE, Mechanical, Civil Engineering BCA, MCA, BBA
//               and MBA students etc
//             </p>
//           </Carou>
//         </div>
//       </div>
//     </Contain>
//   );
// }

// export default CarouselMain;

import React from "react";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 1 },
];

  const Head = styled.p`
    font-size: 32px;
    color: #ca3040;
    @media only screen and (max-width: 768px) {
    font-size: 16px;
    }
    @media only screen and (max-width: 820px) {
    font-size: 16px;
    }
  `;
  const Carou = styled.div`
    padding: 40px;
    color: #707070;
    font-size: 20px;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 820px) {
      font-size: 12px;
    }
  `;

function CarouselMain() {
  const Item = styled.div`
    /* display: flex;
    justify-content: start;
    align-items: center; */
    height: 450px;
    width: 100%;
    /* padding: 10px; */
    /* background-color: #00008B; */
    color: #fff;
    display: grid;
    align-items: center;
    /* margin: 0 15px; */
    /* font-size: 4em; */
    @media only screen and (max-width: 768px) {
      height: 330px;
    }
    @media only screen and (max-width: 820px) {
      height: 330px;
    }
  `;

  return (
    <>
      <div>
        <Carousel breakPoints={breakPoints}>
        <Item>
        <Carou>
           <Head>
               Get trained candidates ready to take on the jobs of tomorrow
             </Head>
             <p style={{ color: "rgb(72 68 68)" }}>
             Hire Interns and Freshers trained specially for your company for technical and non technical roles
             </p>
           </Carou>
        </Item>
        <Item>
        <Carou>
             <Head>
               Launch Internships and Placement Opportunities on our platform to
               students across 4000+ colleges
             </Head>
             <p style={{ color: "rgb(72 68 68)" }}>
               College education, while good, is inadequate— we are building the
               talent pool by connecting interested students, learning providers
               and you via our platform and unique product to help build India’s
               workforce.
             </p>
           </Carou>
        </Item>
        <Item>
        <Carou>
             <Head>
               Work on Live Projects in Technical and Non technical domain
             </Head>
             <p style={{ color: "rgb(72 68 68)" }}>
               Work on Live Projects. Get Paid Internships & Projects for
               engineering students, Work on Live projects with various companies
               for IT, CSE, ECE, EEE, Mechanical, Civil Engineering BCA, MCA, BBA
               and MBA students etc
             </p>
           </Carou>
        </Item>
        
        </Carousel>
      </div>
    </>
  );
}

export default CarouselMain