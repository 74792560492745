import React from 'react'
import styled from 'styled-components'
import WorksCard from "../LandingPage/Extra/WorksCard";
import Pic1 from "../../Assets/Landing_Page/Icons/Hiring.png";
import Pic2 from "../../Assets/Landing_Page/Icons/Discuss.png";
import Pic3 from "../../Assets/Landing_Page/Icons/Updates.png";
import Pic4 from "../../Assets/Landing_Page/Icons/Support.png";

function CompanyMore() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 15px;
  `;
  const Head = styled.p`
    color: #fff;
    margin: 20px !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Sub = styled.p`
    color: #fff;
  `;
    const Bor = styled.div`
        border: 2px solid red; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 20px;
        width: 90%;
    `
    const Text = styled.p`
        font-weight: 600;
        font-size: 20px
    `
    const KMore = styled.span`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    margin: 10px;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 40px;
  `
  return (
    <div className='mb-5'>
    <Contain>
      <div className="text-center">
        <Head>How it works </Head>
        <Sub>What we do</Sub>
      </div>
      <div className="row m-0 mb-4">
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic1}
            lone="We convert your hiring requirements into strategic advantage by empowering your workforce with knowledge and skills even before they join you."
            
          />  
        </div>
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic2}
            lone="Let candidates know about your company, work culture and vision by exposing them to work on interesting projects"
            
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic3}
            lone="Drive meaningful outcomes and create efficiencies for your HR team"
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-3 d-flex justify-content-center">
          <WorksCard
            headIcon={Pic4}
            head="How can we help you achieve your workforce goals?"
            lone="Give us a call to talk through what you need. Let's see how BloomTech can help."

          />
        </div>
      </div>
      <div className="text-center">
        <KMore
          className="btn"
          onClick={() => {
            window.location.pathname = "/";
          }}
        >
         LET'S TALK
        </KMore>
      </div>
    </Contain>

    <div className='d-flex text-center justify-content-center mb-3'>
    <h3>More projects </h3>
    </div>
    <div className="row m-0 p-5">
        <div className="col-md-6 mb-4">
        <Bor>
        <Text>Product Management</Text>
        </Bor>
        </div>
        <div className="col-md-6 mb-4">
        <Bor>
        <Text>Product Launch</Text>
        </Bor>
        </div>
        <div className="col-md-6 mb-4">
        <Bor>
        <Text>Infleuncer Marketing and Community Building</Text>
        </Bor>
        </div>
        <div className="col-md-6 mb-4">
        <Bor>
        <Text>Brand Promotion & Marketing</Text>
        </Bor>
        </div>

        
    </div>
   
   
        
    </div>
  )
}

export default CompanyMore