import React from "react";
import styled from "styled-components";
import pic from "../../Assets/Blogs/hero.png";
import "../../App.css";

function BlogHero() {
  const Text = styled.h2`
    color: #fff;
    padding: 260px;

    @media only screen and (max-width: 921px ){
      padding: 50px;
    }
  `

  return (
    <div >
      <div className="bloghero row  d-flex align-content-center text-center">
        <div className="col-md-12 ">
          <Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
            tempore at eligendi doloribus expedita explicabo laborum, ipsum
            cumque iusto hic?
          </Text>
        </div>
      </div>
    </div>
  );
}

export default BlogHero;
