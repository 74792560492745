import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabOne from '../CompanyPage/TabCard/TabOne';

export default function CompanyTab(props) {


  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={props.name1} value="1" />
            <Tab label={props.name2} value="2" />
            <Tab label={props.name3} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" style={{padding: 0}}>
          {props.tabval1}
        </TabPanel>
        <TabPanel value="2" style={{padding: 0}}>{props.tabval2}</TabPanel>
        <TabPanel value="3" style={{padding: 0}}>{props.tabval3}</TabPanel>
      </TabContext>
    </Box>
  );
}
