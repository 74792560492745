import React from 'react'
import styled from "styled-components";
import pic1 from "../../Assets/About_Page/middle_two.png";

function Mission() {
    const Image = styled.img`
    height: 500px;

    @media only screen and (max-width: 768px){
        height: 360px;
    }
  `;
  const ColOne = styled.div``;
  const Contain = styled.div`
    padding: 60px;
    @media only screen and (max-width: 768px){
      padding: 35px;
    }
  `;
  const InnerCard = styled.div`
    background-color: #fae8ec;
    color: #000;
    padding: 20px;
    border-radius: 25px;
  `;
  const Text = styled.p`
    margin-top: 140px;

    @media only screen and (max-width: 768px){
        margin-top: 25px;
    }
  `;
  const Head = styled.div`
    
  `
  return (
    <div>
      <Contain>
        <div className="row m-0">
          <div className="col-md-6 mb-3 d-flex justify-content-center">
            <InnerCard className="">
              <Head className="d-flex text-center justify-content-center">
                <h4> Our Mission</h4>
              </Head>
              <Text>
                People are not the most important asset, the right people are.
                We aim to get the right candidates through our customised
                programs that fit your organization. We are proud to deliver
                measurable outcomes for ***+ organizations.
              </Text>
            </InnerCard>
          </div>
          <ColOne className="col-md-6 mb-3 d-flex justify-content-start">
            <Image src={pic1} alt="our_vision" />
          </ColOne>
        </div>
      </Contain>
    </div>
  );
}

export default Mission