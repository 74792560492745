import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroMain from "./Components/LandingPage/LandingPage/HeroMain";
import "./App.css"
import Navbar from './Components/Navbar/NavBar';
import { FooterContainer } from './Components/LandingPage/LandingPage/footer/footer';
import AboutMain from './Components/AboutPage/AboutMain';
import CompanyMain from './Components/CompanyPage/CompanyMain';
import BlogMain from './Components/BlogsPage/BlogMain';
import BlogInfoMain from './Components/BlogsPage/Info/BlogInfoMain';
import LearningMain from "./Components/Learning Partners/LearningMain";

function App() {
  return (
    <div className="App">
           <BrowserRouter>
        <Navbar />
        <Routes>
        <Route path="/" element={<HeroMain />}> </Route>
        <Route path="/about" element={<AboutMain />} ></Route>
        <Route path="/company" element={<CompanyMain />}></Route> 
        <Route path="/blog" element={<BlogMain />}></Route> 
        <Route path="/bloginfo" element={<BlogInfoMain />} ></Route>
        <Route path="/learningpartner" element={<LearningMain />} ></Route>
        </Routes>
        <FooterContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
