import React from 'react'
import  styled  from 'styled-components';

function Comsoon() {
    const Heading = styled.h2`
    font-size: 150px;
    color: #fff;
    padding: 300px 100px;
    `
  return (
    <div>
        <div className='learninghero row  d-flex align-content-center text-center'>
            <div className='col-md-12'>
                <Heading>
                    COMING SOON
                </Heading>
            </div>
        </div>
    </div>
  )
}

export default Comsoon