import React from "react";
import {Card } from "react-bootstrap";
import styled from "styled-components";
import Card1 from "../../Assets/Blogs/card1.png";

function BlogCard() {

  const Discri = styled.p`
    color: #707070;
    /* height: 200px */
  `
    const KMore = styled.span`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    margin: 25px;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  `;
  return (
    <>
      <Card style={{ width: "23rem" , height: "32rem"}}>
        <Card.Img variant="top" src={Card1} />
        <Card.Body>
          <Card.Text style={{height: "10rem"}}>
          <Discri>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis
            vero repellat officia excepturi, optio ullam consequatur vitae quis
            ducimus ipsam perspiciatis deleniti totam suscipit nemo ad accusamus
            omnis animi. Dolor.
          </Discri>
          </Card.Text>
          <div className="text-center">
          <KMore className="btn" onClick={()=>{window.location.pathname = "/bloginfo"}}>View More</KMore>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default BlogCard;
