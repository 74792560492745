import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
// import pic from "../../../Assets/whyus.png";
import Wedo from "../../../Assets/Landing_Page/What_We_Do.png";

function WhyUs() {
  const WhyUS = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #fff;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Image = styled.img`
    height: auto;
    @media only screen and (max-width: 921px) {
      height: auto;
    }
  `;
  const Contain = styled.div`
    padding: 40px;
  `;
  const Para = styled.p`
    display: flex;
    justify-content: center;
    align-item: center;
    /* padding: 150px; */
    padding: 60px 50px;
    @media only screen and (max-width: 921px) {
      padding: 15px;
    }
  `;
  const KMore = styled.span`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    margin: 10px;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 40px;
  `;
  const List = styled.li`
    list-style-type: disc;
    font-size: 19px;
  `;
  return (
    <div className="mb-1">
      <WhyUS>
        <Head>Change the traditional way of hiring</Head>
      </WhyUS>
      <Contain>
        <div className="row m-0">
          {/* <div className="col-md-12 justify-content-center text-center d-flex">
          <Image src={pic} alt="" className="img-fluid" />
        </div> */}
          <div className="col-md-4">
            <Image src={Wedo} alt="what_we_do" />
          </div>
          <div className="col-md-8 d-flex justify-content-center">

            <div className="d-flex">
              <Para>
               
                <ol>
                  <List>
                    We are a one stop platform to for all your hiring
                    requirements for skilled and trained interns and freshers
                  </List>
                  <List>
                    We help align your strategic talent objectives for Freshers
                    and Interns through our unique project model
                  </List>
                  <List>
                  Hire from a pool  of pre-trained, pre-screened candidates
                  </List>
                  <List>
                    It's not only about the skills, it is also about the
                    attitude : Are they the right fit for your company?
                  </List>
                  <List>Work with the interns before you hire them.</List>
                </ol>
              </Para>
            </div>
          </div>
        </div>
        <div className="text-center">
          <KMore
            className="btn"
            onClick={() => {
              window.location.pathname = "/bloginfo";
            }}
          >
            Know More
          </KMore>
        </div>
      </Contain>
    </div>
  );
}

export default WhyUs;
