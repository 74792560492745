import React from "react";
import SuccessCarousel from "./../Extra/SuccessCarousel";
import styled from "styled-components";
import CarouselCard from "../Extra/Carousel_Card";

function Success() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 35px;
    height: 600px;
    @media only screen and (max-width: 768px) {
      padding: 0px;
      height: auto;
    }
    @media only screen and (max-width: 820px) {
      padding: 0px;
      height: auto;
    }
  `;
  const Head = styled.p`
    color: #fff;
    margin: 20px !important;
    font-size: 25px;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      margin: 0px !important;
    }
    @media only screen and (max-width: 820px) {
      margin: 0px !important;
    }
  `;
  return (
    <Contain>
      <div className="text-center">
        <Head>Success stories of our students</Head>
      </div>
      {/* <SuccessCarousel /> */}
      <div className="row m-0">
        <div className="col-12 col-sm-12  col-lg-4 d-flex justify-content-center">
          <CarouselCard
            StudentName="Vikas Agarwal"
            Clg="BIET, Jhansi"
            innerContent="The experience and exposure which I got from this whole process is awesome. My ambition from this to make contacts interact and to help out others."
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-4 d-flex justify-content-center">
          <CarouselCard
            StudentName="Rapuri Bharath"
            Clg="Malla Reddy Engineering College"
            innerContent="Inmovidu helped me in building huge circle to interact with lots of talented and excited people to work with and also improved my experience of interacting with people in different sort of ways Either online or offline that even built my confidence levels in dealing things. I heartfully thank inmovidu for providing me such a great oppurtunity"
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-4 d-flex justify-content-center">
          <CarouselCard
            StudentName="Mrityunjaya Tripathi"
            Clg="IIIT ALLAHABAD"
            innerContent="InMovidu is a owsm platform for engineering students that holds students hand from entering in college till intern and jobs offer . Specially faculties are owsm and very high skilled . I recommend everyone eng students to join Inmovidu to boostup your creativity . It will really help a lot."
          />
        </div>
      </div>
    </Contain>
  );
}

export default Success;
