import React from "react";
import styled from "styled-components";
import Pic from "../../../Assets/Landing_Page/Why_Choose_us.png";
import PicTwo from "../../../Assets/Landing_Page/Why_Choose_us_mob.png";


function Choose() {
  const WhyUS = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #000;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Contain = styled.div`
    padding: 40px;
  `;
    const KMore = styled.span`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    margin: 10px;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 40px;
  `;
  const ImageOne = styled.img`
    display: block;
    @media only screen and (max-width: 750px) {
      display: none;
    }
  `
  const ImageTwo = styled.img`
display: none;
    @media only screen and (max-width: 750px) {
      display: block;
    }
  `

  return (
    <Contain>
      <WhyUS>   
        <Head>Why Choose us</Head>
      </WhyUS>
      <ImageOne src={Pic} alt="why_choose_us" className="img-fluid mb-3"/>
      <ImageTwo src={PicTwo} alt="why_choose_us" className="img-fluid mb-3" />
      <div className="text-center">
        <KMore
          className="btn"
          onClick={() => {
            window.location.pathname = "/bloginfo";
          }}
        >
          Know More
        </KMore>
      </div>
    </Contain>
  );
}

export default Choose;
