import React from 'react'
import AboutHero from './AboutHero'
import Vision from './Vision'
import Mission from './Mission';

function AboutMain() {
  return (
    <div>
        <AboutHero />
        <Vision />
        <Mission />
    </div>
  )
}

export default AboutMain