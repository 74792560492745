import React from "react";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import CarouselCard from "./Carousel_Card";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

function SuccessCarousel() {
  const Item = styled.div`
    /* display: flex;
    justify-content: start;
    align-items: center; */
    height: 450px;
    width: 100%;
    /* padding: 10px; */
    /* background-color: #00008B; */
    color: #fff;
    /* margin: 0 15px; */
    /* font-size: 4em; */
  `;
  return (
    <>
      <div>
        <Carousel breakPoints={breakPoints}>
          <Item>
            <CarouselCard 
            StudentName="Vikas Agarwal"
            Clg="BIET, Jhansi"
            innerContent="The experience and exposure which I got from this whole process is awesome. My ambition from this to make contacts interact and to help out others." />
          </Item>
          <Item>
            <CarouselCard 
            StudentName="Rapuri Bharath"
            Clg="Malla Reddy Engineering College"
            innerContent="Inmovidu helped me in building huge circle to interact with lots of talented and excited people to work with and also improved my experience of interacting with people in different sort of ways Either online or offline that even built my confidence levels in dealing things. I heartfully thank inmovidu for providing me such a great oppurtunity" />
          </Item>
          <Item>
            <CarouselCard 
            StudentName="Mrityunjaya Tripathi"
            Clg="IIIT ALLAHABAD"
            innerContent="InMovidu is a owsm platform for engineering students that holds students hand from entering in college till intern and jobs offer . Specially faculties are owsm and very high skilled . I recommend everyone eng students to join Inmovidu to boostup your creativity . It will really help a lot." />
          </Item>
          <Item>
            <CarouselCard />
          </Item>
          <Item>
            <CarouselCard />
          </Item>
          <Item>
            <CarouselCard />
          </Item>
          <Item>
            <CarouselCard />
          </Item>
          <Item>
            <CarouselCard />
          </Item>
        </Carousel>
      </div>
    </>
  );
}

export default SuccessCarousel;
